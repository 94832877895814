<template>
    <v-footer app :absolute="true" class="grey darken-2" dark padless>
        <!-- スマホ -->
        <v-row v-if="!smAndUp">
            <v-col
                cols="6"
                align="center"
                class="ma-0 pb-0 px-0 border-bottom-sp"
                v-for="elm in gyms.slice(1)"
                :key="elm.name"
            >
                <router-link
                    class="text-decoration-none"
                    :to="elm.to"
                    :target="elm.brank ? '_blank' : ''"
                >
                    <v-btn color="white" text rounded>
                        {{ elm.name }}
                    </v-btn>
                </router-link>
            </v-col>
        </v-row>
        <v-row v-if="!smAndUp">
            <v-col
                cols="12"
                align="center"
                class="ma-0 pb-0 px-0 border-bottom-sp"
            >
                <router-link
                    class="text-decoration-none"
                    :to="footerMenu[0].to"
                >
                    <v-btn color="white" text rounded>
                        {{ footerMenu[0].name }}
                    </v-btn>
                </router-link>
            </v-col>
        </v-row>
        <v-row v-if="!smAndUp">
            <v-col
                v-for="elm in footerMenu.slice(3)"
                :key="elm.name"
                cols="6"
                align="center"
                class="ma-0 pb-0 px-0 border-bottom-sp"
            >
                <router-link
                    class="text-decoration-none"
                    :to="elm.to"
                    :target="elm.brank ? '_blank' : ''"
                >
                    <v-btn color="white" text rounded>
                        {{ elm.name }}
                    </v-btn>
                </router-link>
            </v-col>
        </v-row>
        <v-row v-if="!smAndUp">
            <v-col
                cols="6"
                align="center"
                class="ma-auto pt-0 border-bottom-sp"
            >
                <router-link
                    class="text-decoration-none"
                    :to="footerMenu[1].to"
                >
                    <v-btn color="white" text rounded>
                        {{ footerMenu[1].name }}
                    </v-btn>
                </router-link>
            </v-col>
            <v-col
                cols="6"
                align="center"
                class="ma-auto pt-0 border-bottom-sp"
            >
                <router-link
                    class="text-decoration-none"
                    :to="footerMenu[2].to"
                >
                    <v-btn color="white" text rounded>
                        {{ footerMenu[2].name }}
                    </v-btn>
                </router-link>
            </v-col>
            <v-col cols="12">
                <v-card flat tile>
                    <v-card-text class="text-overline white" align="center">
                        <div class="black--text">
                            © 2015-2024 Revoist.
                        </div></v-card-text
                    >
                </v-card>
            </v-col>
        </v-row>

        <!-- PC -->
        <v-row justify="center" v-if="smAndUp">
            <v-col cols="8">
                <v-card tile flat>
                    <v-card-text class="border-bottom">
                        <router-link
                            class="text-decoration-none"
                            :to="elm.to"
                            :target="elm.brank ? '_blank' : ''"
                            v-for="elm in gyms.slice(1)"
                            :key="elm.name"
                        >
                            <v-btn color="white" text rounded>
                                {{ elm.name }}
                            </v-btn>
                        </router-link>
                    </v-card-text>
                    <v-card-text class="border-bottom">
                        <router-link
                            class="text-decoration-none"
                            :to="footerMenu[0].to"
                        >
                            <v-btn color="white" text rounded>
                                {{ footerMenu[0].name }}
                            </v-btn>
                        </router-link>
                    </v-card-text>
                    <v-card-text class="border-bottom">
                        <router-link
                            class="text-decoration-none"
                            :to="elm.to"
                            :target="elm.brank ? '_blank' : ''"
                            v-for="elm in footerMenu.slice(3)"
                            :key="elm.name"
                        >
                            <v-btn color="white" text rounded>
                                {{ elm.name }}
                            </v-btn>
                        </router-link>
                    </v-card-text>
                    <v-card-text class="border-bottom">
                        <router-link
                            class="text-decoration-none"
                            :to="footerMenu[1].to"
                        >
                            <v-btn color="white" text rounded>
                                {{ footerMenu[1].name }}
                            </v-btn>
                        </router-link>
                        <router-link
                            class="text-decoration-none"
                            :to="footerMenu[2].to"
                        >
                            <v-btn color="white" text rounded>
                                {{ footerMenu[2].name }}
                            </v-btn>
                        </router-link>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card-text
                    class="text-overline white border-bottom"
                    align="center"
                    ><div class="black--text">
                        © 2015-2024 Revoist.
                    </div></v-card-text
                >
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    props: {
        gyms: {
            type: Array,
            required: true,
        },
        footerMenu: {
            type: Array,
            required: true,
        },
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped>
.border-bottom {
    border-bottom: 1px dotted #e0e0e0 !important;
    background-color: #616161;
}
.border-bottom-sp {
    background-color: #616161;
}
</style>
