<template>
    <v-container>
        <v-card
            outlined
            class="ma-auto trial-lesson"
            :max-width="smAndUp ? '80%' : '100%'"
        >
            <v-card-title
                class="text-h4 font-weight-light title text-center"
                :class="smAndUp ? 'text-h4' : 'text-h5'"
            >
                <span class="under">体験レッスンのご案内</span>
            </v-card-title>
            <v-img
                :key="imageTrialLesson"
                :src="imageTrialLesson"
                class="my-4"
            ></v-img>
            <v-card-text class="text-body-1 ma-auto">
                選べる３つの体験レッスン！<br />
                無料体験プランもご用意！<br /><br />
                相談プラン<br />
                カウンセリング＆施設見学<br /><br />
                納得プラン<br />
                カウンセリング＆2部位トレーニング<br /><br />
                本気プラン<br />
                カウンセリング＆4部位トレーニング<br /><br />
                男性も女性も、運動初心者大歓迎！<br />
                LINEでお問い合わせも簡単にできます♪
            </v-card-text>
            <v-btn
                class="mt-1 white--text"
                color="#E25959"
                href="https://lin.ee/bFpWQ0A"
                block
                x-large
            >
                体験レッスンの問い合わせ ></v-btn
            >
        </v-card>
    </v-container>
</template>

<script>
export default {
    computed: {
        imageTrialLesson() {
            return this.$vuetify.breakpoint.smAndUp
                ? '/static/pc_top/pixta_35383873_XL.png'
                : '/static/sp_top/img_tailen.png'
        },
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    display: block !important;
}
.trial-lesson {
    border-width: 1em;
    border-color: #f3e3b6;
}
.menu-2 {
    min-height: 300px;
}
.under {
    background: linear-gradient(transparent 70%, #f6cfcf 70%);
}

.link-tag {
    width: 100%;
    height: 100px;
    text-align: center;
    vertical-align: middle;
    border: 1px dashed;
    border-width: 1px 0 0 0;
    border-color: #666666;
}
.link-tag:after {
    font-family: 'Font Awesome 5 Free';
    content: '\f105';
    font-weight: 900;
    top: 50%;
    right: 10px;
    margin-top: -12px;
    position: absolute;
    color: #c30013;
}
</style>
