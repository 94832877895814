<template>
    <div>
        <v-sheet class="mb-10 background">
            <v-container>
                <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                    <v-col cols="12" md="10" align="center">
                        <div
                            class="font-weight-light"
                            :class="
                                smAndUp
                                    ? 'text-h2 my-4 '
                                    : 'text-h5 font-weight-light'
                            "
                        >
                            <span class="red--text">体験</span>レッスンのご案内
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        md="10"
                        :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                    >
                        選べる３つの体験レッスン！<br />無料体験プランもご用意！<br /><br />
                        相談プラン<br />
                        カウンセリング＆施設見学<br /><br />
                        納得プラン<br />
                        カウンセリング＆2部位トレーニング<br /><br />
                        本気プラン<br />
                        カウンセリング＆4部位トレーニング<br /><br />
                        男性も女性も、運動初心者大歓迎！<br />
                        LINEでお問い合わせも簡単にできます♪
                    </v-col>
                    <v-col cols="8" md="12" align="center" class="pa-0">
                        <v-img
                            :src="imageTrialFlow"
                            :key="imageTrialFlow"
                        ></v-img>
                    </v-col>
                </v-row>
                <v-row justify="center" align="center">
                    <v-col cols="12" md="5" class="outer-goods" align="center">
                        <v-btn
                            class="mt-1 white--text"
                            color="#E25959"
                            href="https://lin.ee/bFpWQ0A"
                            x-large
                        >
                            体験レッスンの問い合わせ >
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <!-- <v-container>
            <v-row justify="center" :class="smAndUp ? 'my-15' : null">
                <v-col
                    cols="8"
                    align="center"
                    :class="
                        smAndUp
                            ? 'arrow_box_bottom mb-10'
                            : 'arrow_box_bottom_sm mb-4'
                    "
                >
                    <div
                        :class="smAndUp ? 'text-h2 my-6 ' : 'text-h6'"
                        class="font-weight-thin green--text"
                    >
                        体験のお申込みはこちら
                    </div>
                </v-col>
                <v-col cols="12" align="center">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="font-weight-thin"
                        v-html="line"
                    ></div>
                </v-col>
                <v-row>
                    <v-col class="ma-auto" cols="10" md="8">
                        <div :class="smAndUp ? '' : 'text-body-2'">
                            LINEの友達追加ボタンから友達登録していただき、ご希望の店舗と体験のご希望の曜日や時間帯の候補をお知らせください。
                            ご質問も受け付けております。できるだけすぐにご返答いたします！
                        </div>
                    </v-col>
                </v-row>
                <v-col cols="12" md="3">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="green--text font-weight-thin"
                    >
                        Step 1
                    </div>
                    <div
                        :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                        class="line-flow"
                    >
                        こちらのリンクからレボイストを友だち追加。
                        <div class="ma-4" align="center">
                            <div v-if="smAndUp" class="ma-2 text-body-1">
                                こちらをクリック
                            </div>
                            <div v-if="smAndUp" class="mb-2">▼</div>
                            <a href="https://lin.ee/bFpWQ0A"
                                ><img
                                    height="48"
                                    border="0"
                                    src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                            /></a>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="3">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="green--text font-weight-thin"
                    >
                        Step 2
                    </div>
                    <div
                        :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                        class="line-flow"
                    >
                        メッセージに対してご返信ください。
                    </div>
                    <v-img
                        contain
                        :class="smAndUp ? 'ma-auto ' : 'mx-4'"
                        :src="contact1"
                    ></v-img>
                </v-col>
                <v-col cols="12" md="3">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="green--text font-weight-thin"
                    >
                        Step 3
                    </div>
                    <div
                        :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                        class="line-flow"
                    >
                        ご希望の店舗やお時間を決めていただきます。
                    </div>
                    <v-img
                        :class="smAndUp ? 'ma-auto ' : 'mx-4 contain'"
                        :src="contact2"
                    ></v-img>
                </v-col>
                <v-col cols="12" md="3">
                    <div
                        :class="smAndUp ? 'text-h4 mb-10 ' : 'text-h6'"
                        class="green--text font-weight-thin"
                    >
                        Step 4
                    </div>
                    <div
                        :class="smAndUp ? 'text-body-1' : 'text-body-2'"
                        class="line-flow"
                    >
                        ご予約日に体験にお越しいただきます。
                    </div>
                    <v-img
                        contain
                        :class="smAndUp ? 'ma-8 mt-0 ' : 'ma-auto'"
                        :src="contact3"
                        max-height="25vh"
                    ></v-img>
                </v-col>
            </v-row>
        </v-container> -->
    </div>
</template>

<script>
export default {
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        imageTrialFlow() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/pc/flow_img.svg'
                : 'static/landing/sp/flow_img.svg'
        },
        line() {
            return '<span class="green--text">LINE</span>で簡単にお申し込み！'
        },
        goods() {
            return '体験時の<span class="red--text">持ち物</span>'
        },
        contact1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_contact/IMG_contact_1@2x.png'
                : 'static/sp_contact/contact_img02@2x.png'
        },
        contact2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_contact/IMG_contact_2@2x.png'
                : 'static/sp_contact/contact_img03@2x.png'
        },
        contact3() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_contact/pixta_24685771_XL@2x.png'
                : 'static/sp_contact/contact_img04@2x.png'
        },
    },
}
</script>

<style lang="scss" scoped>
.line-flow {
    min-height: 7vh;
}

// 吹き出し
.arrow_box_bottom {
    position: relative;
    background: #ffffff;
    border: 10px solid #00c321;
}
.arrow_box_bottom:after,
.arrow_box_bottom:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box_bottom:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 30px;
    margin-left: -30px;
}
.arrow_box_bottom:before {
    border-color: rgba(0, 195, 33, 0);
    border-top-color: #00c321;
    border-width: 44px;
    margin-left: -44px;
}

.arrow_box_bottom_sm {
    position: relative;
    background: #ffffff;
    border: 2px solid #00c321;
}
.arrow_box_bottom_sm:after,
.arrow_box_bottom_sm:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box_bottom_sm:after {
    border-color: rgba(0, 195, 33, 0);
    border-top-color: #ffffff;
    border-width: 15px;
    margin-left: -15px;
}
.arrow_box_bottom_sm:before {
    border-color: rgba(0, 195, 33, 0);
    border-top-color: #00c321;
    border-width: 18px;
    margin-left: -18px;
}

.background {
    background: transparent linear-gradient(159deg, #fff6da 0%, #f8c78f 100%) 0%
        0% no-repeat padding-box;
}
</style>
