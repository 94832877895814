<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="6">
                <heading>ジム風景</heading>
                <v-row
                    class="ma-auto"
                    :class="$vuetify.breakpoint.smAndUp ? 'menu-2' : null"
                >
                    <v-col cols="4" class="mb-0 pa-1">
                        <v-img
                            :key="imageGymInterior1"
                            :src="imageGymInterior1"
                        ></v-img>
                    </v-col>
                    <v-col cols="4" class="mb-0 pa-1">
                        <v-img
                            :key="imageGymInterior2"
                            :src="imageGymInterior2"
                        ></v-img>
                    </v-col>
                    <v-col cols="4" class="mb-0 pa-1">
                        <v-img
                            :key="imageGymInterior3"
                            :src="imageGymInterior3"
                        ></v-img>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                        <div class="text-body-1 ma-2">
                            美容室のようなオシャレでナチュラルなイメージのパーソナルジム。男性にも女性にも、運動初心者にとっても万人がレッスンを受けやすいようなゆったりとした完全個別空間。
                        </div>
                    </v-col>
                </v-row>
                <link-tag compName="Scene">ジム風景</link-tag>
            </v-col>
            <v-col cols="12" md="6" v-if="!$vuetify.breakpoint.smAndUp">
                <heading>トレーナー</heading>
                <v-img :key="imageTranerXL" :src="imageTrainer"></v-img>
                <div class="text-body-1 ma-2">
                    ひとりひとりのカラダの悩みに耳を傾け、価値観や想いに寄り添い、信頼関係を築く。
                    期待に応え続け、通いがいのある楽しいレッスンができるトレーナー集団です。
                </div>
                <link-tag compName="OurTeam">トレーナー</link-tag>
            </v-col>
            <v-col cols="12" md="6" v-if="$vuetify.breakpoint.smAndUp">
                <heading>トレーナー</heading>
                <v-row class="menu-2 ma-auto">
                    <v-col cols="4">
                        <v-img :key="imageTrainer" :src="imageTrainer"></v-img>
                    </v-col>
                    <v-col cols="8">
                        <div class="text-body-1 ma-2">
                            ひとりひとりのカラダの悩みに耳を傾け、価値観や想いに寄り添い、信頼関係を築く。
                            期待に応え続け、通いがいのある楽しいレッスンができるトレーナー集団です。
                        </div>
                    </v-col>
                </v-row>
                <link-tag compName="OurTeam">トレーナー</link-tag>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Heading from '@/components/Heading.vue'
import LinkTag from '@/components/LinkTag.vue'

export default {
    components: {
        Heading,
        LinkTag,
    },
    method: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },

    computed: {
        imageGymInterior1() {
            return '/static/pc_top/11.png'
        },
        imageGymInterior2() {
            return '/static/pc_top/12.png'
        },
        imageGymInterior3() {
            return '/static/pc_top/13.png'
        },

        imageTrainer() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_top/pixta_65324547_XL@2x.png'
                : 'static/pc_top/pixta_65324547_XL@2x.png'
        },
    },
}
</script>

<style lang="scss" scoped>
.menu-2 {
    min-height: 300px;
}
</style>
