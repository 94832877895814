<template>
    <v-container fluid v-if="$vuetify.breakpoint.smAndUp">
        <v-row>
            <v-col cols="12">
                <revo-menu :links="links"></revo-menu>
            </v-col>
        </v-row>
        <v-container>
            <v-row justify="end">
                <v-col align="right">
                    <router-link
                        class="text-decoration-none"
                        :to="elm.to"
                        :target="elm.brank ? '_blank' : ''"
                        v-for="elm in subMenu"
                        :key="elm.name"
                    >
                        <v-btn text class="text-body-1">{{ elm.name }}</v-btn>
                    </router-link>
                    <v-btn
                        class="white--text"
                        color="#E25959"
                        href="https://lin.ee/bFpWQ0A"
                    >
                        体験レッスンの問い合わせ ></v-btn
                    >
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import RevoMenu from '@/components/Menu.vue'
export default {
    components: {
        RevoMenu,
    },
    data() {
        return {
            links: [
                { name: 'レボイストとは', to: { name: 'Revoist' } },
                {
                    name: 'パーソナルトレーニング',
                    to: { name: 'PersonalTraining' },
                },
                { name: '料金・会員種別', to: { name: 'Price' } },
                { name: 'ジム風景', to: { name: 'Scene' } },
                { name: 'トレーナー', to: { name: 'OurTeam' } },
                { name: '店舗一覧', to: { name: 'Gyms' } },
            ],
            subMenu: [
                { name: 'お客さまの声', to: { name: 'Testimonial' } },
                { name: 'よくあるご質問', to: { name: 'Faq' } },
                { name: '会社概要', to: { name: 'Company' } },
                { name: '求人情報', to: { name: 'Recruit' } },
            ],
        }
    },
}
</script>

<style lang="scss" scoped></style>
