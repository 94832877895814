<template>
    <div>
        <!-- Breadcrumbs -->
        <bread-crumbs :breadCrumbs="breadCrumbs" />

        <!-- Hero -->
        <v-container>
            <v-row justify="center">
                <v-col cols="12" align="center">
                    <h1>
                        <div
                            class="font-weight-light"
                            :class="smAndUp ? 'text-h3' : 'text-h5'"
                        >
                            パーソナルジム <br />
                            REVOIST {{ shopName }}
                        </div>
                    </h1>
                </v-col>
                <div class="d-flex flex-row mt-10 mb-6 align-center">
                    <div
                        class="font-weight-medium"
                        :class="smAndUp ? 'text-h4' : 'text-body-2 pa-0'"
                    >
                        1か月後のあなたは、別人。
                    </div>
                    <v-btn
                        class="mt-1 white--text"
                        color="#E25959"
                        @click="$vuetify.goTo('#description')"
                        ><v-icon>fas fa-chevron-down</v-icon><v-spacer />
                        <div
                            class="font-weight-medium"
                            :class="smAndUp ? 'text-h6' : 'text-caption'"
                        >
                            店舗情報・アクセス
                        </div>
                    </v-btn>
                </div>

                <v-col
                    cols="12"
                    align="center"
                    :class="
                        smAndUp ? 'image-container' : 'image-container mb-n16'
                    "
                >
                    <v-img
                        class="trim"
                        :src="imageMain"
                        :key="imageMain"
                        height="80%"
                    ></v-img>
                    <!-- overlay text -->
                    <div class="text-overlay">※2024年1月時点 当社調べ</div>
                </v-col>

                <!-- メイン -->
                <!-- <main-component /> -->
            </v-row>
        </v-container>
        <div class="mb-n8"></div>
        <!-- <news /> -->

        <!-- Recommend -->
        <recommend />
        <!-- レボイストとは -->
        <v-container fluid>
            <v-row justify="center">
                <v-col cols="auto" align="left">
                    <div
                        class="font-weight-light"
                        :class="smAndUp ? 'text-h4 my-4 ' : 'text-h6 my-4'"
                    >
                        ひとりひとりの<br /><br
                            v-show="smAndUp"
                        />カラダの悩みに寄り添い<br /><br
                            v-show="smAndUp"
                        />楽しく一緒に人生を変えていく
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <!-- レボイスト、3つのポリシー -->
        <v-container fluid>
            <v-row justify="center">
                <v-col cols="auto" align="center">
                    <heading class="under font-weight-medium"
                        >レボイスト<span class="red--text text--darken-4"
                            >3 </span
                        >つのポリシー
                    </heading>
                </v-col>
                <v-col cols="12" align="center">
                    <div
                        class="font-weight-light"
                        :class="
                            $vuetify.breakpoint.smAndUp
                                ? 'text-h6 my-4 '
                                : 'text-body-1 my-4'
                        "
                    >
                        〜選ばれる理由〜
                    </div>
                </v-col>
                <v-row :justify="smAndUp ? 'center' : 'center'" class="mb-12">
                    <v-col cols="4" md="3" :class="smAndUp ? '' : 'ma-auto'">
                        <v-img
                            :key="imageOncePerAWeek"
                            :src="imageOncePerAWeek"
                        >
                        </v-img>
                        <div
                            style="text-align: center"
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                        >
                            週1回で体は<br />十分変わります
                        </div>
                    </v-col>
                    <v-col cols="4" md="3" :class="smAndUp ? '' : 'ma-auto'">
                        <v-img :key="imageBestPrice" :src="imageBestPrice">
                        </v-img>
                        <div
                            style="text-align: center"
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                        >
                            高額でない<br />続けやすい料金
                        </div>
                    </v-col>
                    <v-col cols="4" md="3" :class="smAndUp ? '' : 'ma-auto'">
                        <v-img
                            :key="imageCommunityBased"
                            :src="imageCommunityBased"
                        >
                        </v-img>
                        <div
                            style="text-align: center"
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                        >
                            いつでも気軽に<br />通いやすく
                        </div>
                    </v-col>
                </v-row>
            </v-row>
        </v-container>

        <!-- Voice of customer -->
        <voice-of-customer />

        <!-- Questions -->
        <questions />

        <!-- Facility -->
        <facilities />

        <!-- Trainer's Commitment -->
        <trainers-commitment />

        <!-- 価格方針 -->
        <price-strategy />

        <!-- 価格・会員種別 -->
        <price-and-member />

        <!-- Trial Lesson -->
        <trial-lesson />

        <slot></slot>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import TrainersCommitment from '@/components/landing/TrainersCommitment.vue'
import Recommend from '@/components/landing/Recommend.vue'
import VoiceOfCustomer from '@/components/landing/VoiceOfCustomer.vue'
import Questions from '@/components/landing/Questions.vue'
import Facilities from '@/components/landing/Facilities.vue'
import PriceAndMember from '@/components/landing/PriceAndMember.vue'
import PriceStrategy from '@/components/landing/PriceStrategy.vue'
import TrialLesson from '@/components/landing/TrialLesson.vue'
import BreadCrumbs from '@/components/landing/BreadCrumbs.vue'
// import News from '@/components/landing/News.vue'
// import MainComponent from '@/components/landing/MainComponent.vue'

export default {
    components: {
        Heading,
        TrainersCommitment,
        Recommend,
        VoiceOfCustomer,
        Questions,
        Facilities,
        PriceAndMember,
        PriceStrategy,
        TrialLesson,
        BreadCrumbs,
        // News,
        // MainComponent,
    },
    methods: {},
    props: {
        shopName: {
            required: true,
            type: String,
            default: () => 'ダミー店舗',
        },
        breadCrumbs: {
            required: true,
            type: Array,
            default: () => [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'ランディングページ',
                    disabled: true,
                    href: 'personal-training',
                },
            ],
        },
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        imageMain() {
            return this.smAndUp
                ? '/static/landing/pc/main.png'
                : '/static/landing/sp/main@2x.png'
        },
        imageOncePerAWeek() {
            return '/static/pc_top/once_per_a_week.png'
        },
        imageBestPrice() {
            return '/static/pc_top/best_price.png'
        },
        imageCommunityBased() {
            return '/static/pc_top/community_based.png'
        },
    },
}
</script>

<style lang="scss" scoped>
.under {
    background: linear-gradient(transparent 70%, #f6cfcf 70%);
}
.trim.v-image ::v-deep .v-image__image {
    background-position: 15% 90% !important;
}
/* 共通スタイル */
.image-container {
    position: relative;
    display: inline-block;
}

.image-container img {
    display: block;
    width: 100%;
}

.text-overlay {
    position: absolute;
    color: black;
    background-color: rgba(222, 213, 213, 0.5); /* 半透明の背景 */
    padding: 2px;
    border-radius: 3px;
}

/* PC用スタイル (デフォルト: 横幅768px以上) */
.text-overlay {
    top: 72%;
    right: 48%;
    font-size: 12px; /* PCでは少し大きめのフォント */
}

/* スマホ用スタイル (横幅768px未満) */
@media (max-width: 767px) {
    .text-overlay {
        top: 76%; /* スマホでは少し高めに配置 */
        right: 11px;
        font-size: 10px; /* スマホでは小さめのフォント */
        padding: 1px;
    }
}
</style>
