<template>
    <div>
        <v-app-bar
            fluid
            app
            dense
            elevate-on-scroll
            elevation="1"
            color="#F5F5F5"
            extension-height="65"
            v-if="smAndUp || $route.name !== 'Top'"
        >
            <v-app-bar-title>
                <router-link class="text-decoration-none" :to="{ name: 'Top' }">
                    <v-img :src="imageLogo" class="ma-auto"></v-img>
                </router-link>
            </v-app-bar-title>
            <div
                class="ml-6 logo-title text-overline"
                v-if="smAndUp"
                v-html="logoTitle"
            ></div>

            <v-spacer></v-spacer>
            <v-app-bar-nav-icon
                v-if="!smAndUp"
                @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>

            <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
                <router-link
                    class="text-decoration-none mt-1"
                    :to="elm.to"
                    :target="elm.brank ? '_blank' : ''"
                    v-for="elm in subMenu"
                    :key="elm.name"
                >
                    <v-btn text class="text-body-1">{{ elm.name }}</v-btn>
                </router-link>
                <v-btn
                    class="mt-1 white--text"
                    color="#E25959"
                    href="https://lin.ee/bFpWQ0A"
                >
                    体験レッスンの問い合わせ ></v-btn
                >
            </v-toolbar-items>
            <template v-slot:extension v-if="$vuetify.breakpoint.mdAndUp">
                <revo-menu :links="links.slice(1)"></revo-menu>
            </template>
        </v-app-bar>
        <v-app-bar
            fluid
            app
            elevate-on-scroll
            elevation="2"
            color="white"
            v-else
        >
            <v-app-bar-title>
                <router-link class="text-decoration-none" :to="{ name: 'Top' }">
                    <v-img :src="imageLogoForSpMv" class="ma-auto"></v-img>
                </router-link>
            </v-app-bar-title>

            <v-spacer></v-spacer>
            <v-app-bar-nav-icon
                v-if="!smAndUp"
                @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" fixed right temporary>
            <v-card>
                <v-card-text>
                    <v-btn
                        block
                        class="white--text"
                        color="#E25959"
                        href="https://lin.ee/bFpWQ0A"
                    >
                        体験レッスンの問い合わせ >
                    </v-btn>
                </v-card-text>
            </v-card>
            <v-card flat>
                <v-card-text>
                    <v-list nav dense>
                        <v-list-item-group v-model="selectedItem1">
                            <v-list-item v-for="link in menu" :key="link.name">
                                <v-list-item-title class="border-bottom">
                                    <router-link
                                        class="text-decoration-none black--text menu-item"
                                        :to="link.to"
                                        :target="link.brank ? '_blank' : ''"
                                        >{{ link.name }}</router-link
                                    >
                                </v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </v-card>

            <v-card flat>
                <v-card-text>
                    <v-list nav dense>
                        <v-list-item-group v-model="selectedItem2">
                            <v-list-item v-for="link in gyms" :key="link.name">
                                <v-list-item-title class="border-bottom">
                                    <router-link
                                        class="text-decoration-none black--text menu-item"
                                        :to="link.to"
                                        :target="link.brank ? '_blank' : ''"
                                        >{{ link.name }}</router-link
                                    >
                                </v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </v-card>
            <v-card flat>
                <v-card-text>
                    <v-list nav dense>
                        <v-list-item-group v-model="selectedItem3">
                            <v-list-item
                                v-for="link in subMenu"
                                :key="link.name"
                            >
                                <v-list-item-title class="border-bottom">
                                    <router-link
                                        class="text-decoration-none black--text menu-item"
                                        :to="link.to"
                                        :target="link.brank ? '_blank' : ''"
                                        >{{ link.name }}</router-link
                                    >
                                </v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>
    </div>
</template>

<script>
import _ from 'lodash'
import RevoMenu from '@/components/Menu.vue'

export default {
    props: {
        links: {
            types: Array,
            required: true,
        },
        subMenu: {
            type: Array,
            required: true,
        },
        gyms: {
            type: Array,
            required: true,
        },
    },
    components: {
        RevoMenu,
    },
    data() {
        return {
            drawer: false,
            selectedItem1: null,
            selectedItem2: null,
            selectedItem3: null,
        }
    },
    watch: {
        selectedItem1(val) {
            if (val !== null) {
                this.selectedItem2 = null
                this.selectedItem3 = null
            }
        },
        selectedItem2(val) {
            if (val !== null) {
                this.selectedItem1 = null
                this.selectedItem3 = null
            }
        },
        selectedItem3(val) {
            if (val !== null) {
                this.selectedItem2 = null
                this.selectedItem1 = null
            }
        },
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },

        imageLogo() {
            return 'static/page_logo.svg'
        },
        imageLogoForSpMv() {
            return 'static/sp_top/logo_revoist01.svg'
        },
        logoTitle() {
            return 'パーソナルトレーニングを<br />受けやすく、続けやすく'
        },
        menu() {
            const menu = _.cloneDeep(this.links)
            menu.splice(6, 1)
            return menu
        },
    },
}
</script>

<style lang="scss" scoped>
.logo-title {
    line-height: 1rem;
}
.border-bottom {
    border-bottom: 1px dotted #e0e0e0 !important;
}
.menu-item {
    padding: 15px;
    width: 100%;
    height: 100px;
    text-align: center;
    vertical-align: middle;
}
.menu-item:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f105';
    font-weight: 900;
    top: 50%;
    left: 10px;
    margin-top: -8px;
    position: absolute;
    color: #c30013;
}
</style>
